import React, { useState } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Container from "../components/Container"
import ThemeContext from "../context/ThemeContext"
import SectionHeader from "../components/Portfolio/SectionHeader"
import AccordionSection from "../components/Portfolio/AccordionSection"
import AccordionLink from "../components/Portfolio/AccordionLink"
import PortfolioIcon from "../components/Portfolio/PortfolioIcon"
import PrivateIcon from "../components/Portfolio/PrivateIcon"
import Arrow2 from "../components/Arrow2"
import Moonbird2 from "../components/Moonbird2"

const Portfolio = ({ data }) => {
  const [selectedSection, setSelectedSection] = useState(0)

  const setAccordionSection = id => {
    setSelectedSection(id)
  }

  const infrastructure = [
    {
      title: "L0 Networks",
      list: [
        {
          name: "Cosmos",
          link: "https://cosmos.network/",
          icon: "",
        },
        {
          name: "Polkadot",
          link: "https://polkadot.network/",
          icon: <PortfolioIcon />,
        },
        {
          name: "SupraOracles",
          link: "https://supraoracles.com/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Ren",
          link: "https://renproject.io/",
          icon: <PortfolioIcon />,
        },
      ],
    },
    {
      title: "L1 Networks",
      list: [
        {
          name: "Ethereum",
          link: "https://ethereum.org/en/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Avalanche",
          link: "https://www.avax.network/",
          icon: "",
        },
        {
          name: "Fantom",
          link: "https://fantom.foundation/",
          icon: "",
        },
        {
          name: "Solana",
          link: "https://solana.com/",
          icon: "",
        },
      ],
    },
    {
      title: "Information Layer",
      list: [
        {
          name: "TheGraph",
          link: "https://thegraph.com/en/",
          icon: "",
        },
        {
          name: "Arweave",
          link: "https://www.arweave.org/",
          icon: "",
        },
        {
          name: "Filecoin",
          link: "https://filecoin.io/",
          icon: "",
        },
        {
          name: "Siacoin",
          link: "https://sia.tech/",
          icon: "",
        },
        {
          name: "Pocket Network",
          link: "https://www.pokt.network/",
          icon: <PortfolioIcon />,
        },
        {
          name: "API3",
          link: "https://api3.org/",
          icon: "",
        },
        {
          name: "Infura",
          link: "https://infura.io/",
          icon: "",
        },
        {
          name: "Alchemy",
          link: "https://www.alchemy.com/",
          icon: "",
        },
        {
          name: "Ceramic Network",
          link: "https://ceramic.network/",
          icon: "",
        },
      ],
    },
    {
      title: "Processing Layer",
      list: [
        {
          name: "Polygon",
          link: "https://www.polygon.com/",
          icon: "",
        },
        {
          name: "Theta",
          link: "https://www.thetatoken.org/",
          icon: "",
        },
        {
          name: "zkSync",
          link: "https://zksync.io/",
          icon: <PrivateIcon />,
        },
        {
          name: "KeeperDAO",
          link: "https://www.rook.fi/",
          icon: <PortfolioIcon />,
        },
        {
          name: "0x",
          link: "https://www.0x.org/",
          icon: "",
        },
        {
          name: "Dfinity",
          link: "https://dfinity.org/",
          icon: "",
        },
      ],
    },
    {
      title: "Presentation",
      list: [
        {
          name: "ERC-20",
          link: "https://ethereum.org/en/developers/docs/standards/tokens/erc-20/",
          icon: "",
        },
        {
          name: "ERC-721",
          link: "https://ethereum.org/en/developers/docs/standards/tokens/erc-721/",
          icon: "",
        },
        {
          name: "ENS",
          link: "https://ens.domains/",
          icon: "",
        },
        {
          name: "SPL",
          link: "https://spl.solana.com/",
          icon: "",
        },
      ],
    },
    {
      title: "Software",
      list: [
        {
          name: "Hardhat",
          link: "https://hardhat.org/",
          icon: <PrivateIcon />,
        },
        {
          name: "Truffle",
          link: "https://trufflesuite.com/",
          icon: <PrivateIcon />,
        },
        {
          name: "Foundry",
          link: "https://github.com/foundry-rs/foundry",
          icon: <PrivateIcon />,
        },
        {
          name: "Brownie",
          link: "https://eth-brownie.readthedocs.io/",
          icon: <PrivateIcon />,
        },
      ],
    },
    {
      title: "Interactions",
      list: [
        {
          name: "Zapper",
          link: "https://zapper.fi/",
          icon: <PrivateIcon />,
        },
        {
          name: "MetaMask",
          link: "https://metamask.io/",
          icon: <PrivateIcon />,
        },
        {
          name: "WalletConnect",
          link: "https://walletconnect.com/",
          icon: <PrivateIcon />,
        },
      ],
    },
  ]

  const applications = [
    {
      groupName: "Defi",
      applications: [
        {
          name: "Uniswap",
          link: "https://uniswap.org/",
          icon: "",
        },
        {
          name: "SushiSwap",
          link: "https://www.sushi.com/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Convex Finance",
          link: "https://www.convexfinance.com/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Yearn Finance",
          link: "https://yearn.finance/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Curve Finance",
          link: "https://curve.fi/",
          icon: "",
        },
        {
          name: "Frax Finance",
          link: "https://frax.finance/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Olympus",
          link: "https://www.olympusdao.finance/",
          icon: "",
        },
      ],
    },
    {
      groupName: "NFT",
      applications: [
        {
          name: "LimeWire",
          link: "https://limewire.com/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Decentraland",
          link: "https://decentraland.org/",
          icon: "",
        },
        {
          name: "LooksRare",
          link: "https://looksrare.org/",
          icon: <PortfolioIcon />,
        },
        {
          name: "PROOF Collective",
          link: "https://collective.proof.xyz/",
          icon: <PortfolioIcon />,
        },
        {
          name: "SuperRare",
          link: "https://superrare.com/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Audius",
          link: "https://audius.co/",
          icon: "",
        },
        {
          name: "Moonbirds",
          link: "https://www.moonbirds.xyz/",
          icon: <PortfolioIcon />,
        },
        {
          name: "BAYC",
          link: "https://boredapeyachtclub.com/",
          icon: "",
        },
        {
          name: "CryptoPunks",
          link: "https://www.larvalabs.com/cryptopunks",
          icon: "",
        },
        {
          name: "Axie",
          link: "https://axieinfinity.com/",
          icon: "",
        },
        {
          name: "Treeverse",
          link: "https://www.treeverse.net/",
          icon: <PortfolioIcon />,
        },
        {
          name: "The Sandbox",
          link: "https://www.sandbox.game/en/",
          icon: "",
        },
      ],
    },
    {
      groupName: "DAO",
      applications: [
        {
          name: "BlackPool",
          link: "https://blackpool.finance/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Flamingo DAO",
          link: "https://flamingodao.xyz/",
          icon: "",
        },
        {
          name: "FriendsWithBenefits",
          link: "https://www.fwb.help/",
          icon: "",
        },
        {
          name: "BitDAO",
          link: "https://www.bitdao.io/",
          icon: "",
        },
      ],
    },
    {
      groupName: "IT",
      applications: [
        {
          name: "Lido",
          link: "https://lido.fi/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Rocket Pool",
          link: "https://rocketpool.net/",
          icon: <PortfolioIcon />,
        },
        {
          name: "Gnosis",
          link: "https://gnosis.io/",
          icon: "",
        },
        {
          name: "Snapshot",
          link: "https://snapshot.org/",
          icon: "",
        },
      ],
    },
  ]

  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo
            title="Portfolio"
            description="Investing in a decentralised future: From possible to probable. From building out the infrastructure, to shaking up entire industries. Our investment approach is to back companies whose capabilities bring the Web3 vision closer to reality."
          />
          <Container>
            <div className="pt-6 md:pt-16 lg:pt-36">
              <div
                className="grid grid-cols-8"
                data-sal="slide-up"
                data-sal-delay={100}
                data-sal-duration="500"
                data-sal-easing="ease-out"
              >
                <div className="col-span-8 md:col-span-6">
                  <h1 className="border rounded-full uppercase inline-block tracking-widest px-2 leading-tight text-sm mb-3">
                    Portfolio
                  </h1>
                  <h2 className="text-[3rem] lg:text-[4rem] leading-none font-light mb-12 md:mb-20">
                    Investing in a decentralised future: From possible to{" "}
                    <span className={`${theme.playful ? "font-mono" : ""}`}>
                      probable
                    </span>
                    .
                  </h2>
                </div>
              </div>
              <div
                className="grid grid-cols-8 overflow-auto"
                data-sal="slide-up"
                data-sal-delay={100}
                data-sal-duration="500"
                data-sal-easing="ease-out"
              >
                <div className="md:col-span-2"></div>
                <div className="col-span-8 md:col-span-6">
                  <div className="text-xl md:text-2xl leading-tight font-light mb-12 prose">
                    <p>
                      From building out the infrastructure, to shaking up entire
                      industries. Our investment approach is to back companies
                      whose capabilities bring the Web3 vision closer to
                      reality.
                    </p>
                    <p>
                      You'll find the DeepTech family investments shaking things
                      up across the full Web3 stack, within their own industries
                      - and creating the protocols needed for back end and front
                      end decentralised functionality. Explore the breadth and
                      depth of Web3 protocols and companies.
                    </p>
                  </div>
                </div>
              </div>
              <Moonbird2
                className={`relative md:left-[5%] max-w-[25rem] md:max-w-[35rem] -mt-[25%] md:-mt-[8rem] mr-2 ${
                  theme.playful ? "block md:visible" : "hidden md:invisible"
                }`}
              />
            </div>
          </Container>

          <div className="border-t pt-8"></div>
          <Container>
            <div className="md:flex md:justify-between md:items-end">
              <div className="">
                <h1 className="text-5xl md:text-6xl font-light leading-none mb-5 md:mb-0 pr-12">
                  The Web3
                  <br /> Ecosystem
                </h1>
              </div>

              <div className="md:flex md:justify-end items-center pb-1">
                <div className="mr-8 tracking-widest font-light">KEY:</div>
                <div className="mr-6 flex items-center">
                  <PortfolioIcon />
                  <div className="inline-block ml-3">Portfolio companies</div>
                </div>
                <div className="flex items-center">
                  <PrivateIcon />
                  <div className="inline-block ml-3">Private companies</div>
                </div>
              </div>
            </div>

            <div className="py-6">
              <SectionHeader>Infrastructure</SectionHeader>

              <div className="md:flex md:flex-row md:h-auto">
                {infrastructure.map((section, idx) => (
                  <AccordionSection
                    title={section.title}
                    list={section.list}
                    index={idx}
                    key={idx}
                    sectionSelect={setAccordionSection}
                    selected={selectedSection === idx ? true : false}
                  />
                ))}
              </div>
            </div>

            <div className="py-6">
              <SectionHeader>Applications by industry</SectionHeader>

              <div
                className={`grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4`}
              >
                {applications.map((application, idx) => (
                  <div className="text-center border mt-6 p-6" key={idx}>
                    <div
                      className={`-translate-y-10 px-12 py-0.5 rounded-full inline-block uppercase tracking-widest font-light ${
                        theme.playful
                          ? "text-deeptech-purple bg-deeptech-silver"
                          : "text-black bg-deeptech-silver"
                      } `}
                    >
                      {application.groupName}
                    </div>
                    <div>
                      <ul>
                        {application.applications.map((org, idx) => (
                          <li
                            className={`text-left w-full py-1.5 text-lg font-light border-b ${
                              theme.playful
                                ? "border-[#382390]"
                                : "border-[#353535]"
                            }`}
                            key={idx}
                          >
                            <AccordionLink
                              text={org.name}
                              icon={org.icon}
                              link={org.link}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Container>

          <div className="md:mt-16 md:flex md:justify-between mx-auto max-w-[100rem]">
            <div className="flex flex-col justify-end items-end w-full">
              <button
                onClick={theme.toggleContact}
                className="text-left font-light w-4/5 md:w-1/2 border border-b-0 border-r-0 text-xl 2xl:border-r p-4 flex justify-between max-w-[32.5rem]"
              >
                <div className="pr-12">
                  Helping to make Web3 a reality?
                  <br />
                  <span className="underline">Get in touch</span>.
                </div>
                <Arrow2 />
              </button>
            </div>
          </div>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}

export default Portfolio
