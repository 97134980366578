import React from "react"
import ThemeContext from "../../context/ThemeContext"

const SectionHeader = props => (
  <ThemeContext.Consumer>
    {theme => (
      <div className="font-light relative py-5 before:absolute before:top-1/2 before:left-0 before:right-0 before:bg-current before:h-px">
        <div className="relative text-center before:left-0 before:h-[26px] before:absolute before:border-current before:bg-current before:w-px before:top-1/2 after:right-0 after:h-[26px] after:absolute after:border-current after:bg-current after:w-px after:top-1/2">
          <div
            className={`relative border rounded-full inline-block py-1.5 px-5 text-2xl ${
              theme.playful ? "bg-deeptech-purple" : "bg-black"
            }`}
          >
            {props.children}
          </div>
        </div>
      </div>
    )}
  </ThemeContext.Consumer>
)

export default SectionHeader
