import React from "react"

const PortfolioIcon = props => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline-block"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM10.1302 10.1281C10.8039 9.45441 11.2186 8.52779 11.2186 7.5H12.2302C12.2302 10.1119 10.114 12.2282 7.50202 12.2282C4.89007 12.2282 2.77381 10.1119 2.77381 7.5H3.78541C3.78541 8.52779 4.20016 9.45441 4.87389 10.1281C5.54761 10.8019 6.47424 11.2166 7.50202 11.2166C8.52981 11.2166 9.45643 10.8019 10.1302 10.1281ZM5.08835 6.70286C5.86604 6.70286 6.49649 6.07241 6.49649 5.29471C6.49649 4.51701 5.86604 3.88657 5.08835 3.88657C4.31065 3.88657 3.6802 4.51701 3.6802 5.29471C3.6802 6.07241 4.31065 6.70286 5.08835 6.70286ZM11.3198 5.29471C11.3198 6.07241 10.6894 6.70286 9.91165 6.70286C9.13396 6.70286 8.50351 6.07241 8.50351 5.29471C8.50351 4.51701 9.13396 3.88657 9.91165 3.88657C10.6894 3.88657 11.3198 4.51701 11.3198 5.29471Z"
      fill="currentColor"
    />
  </svg>
)

export default PortfolioIcon
