import React from "react"

const AccordionButton = ({ open = false }) => {
  if (open) {
    return (
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5009 31.6395C24.8622 31.6395 31.6404 24.8613 31.6404 16.5C31.6404 8.13867 24.8622 1.36047 16.5009 1.36047C8.13953 1.36047 1.36133 8.13867 1.36133 16.5C1.36133 24.8613 8.13953 31.6395 16.5009 31.6395Z"
          fill="#C5CCCA"
          stroke="#C5CCCA"
          strokeMiterlimit="10"
        />
        <path
          d="M9.65039 16.5H23.3481"
          stroke="#5436D2"
          strokeMiterlimit="10"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.98828 16.5H22.686" stroke="#C5CCCA" strokeMiterlimit="10" />
        <path
          d="M15.8379 23.3489V9.65118"
          stroke="#C5CCCA"
          strokeMiterlimit="10"
        />
        <path
          d="M15.8388 31.6395C24.2001 31.6395 30.9783 24.8613 30.9783 16.5C30.9783 8.13867 24.2001 1.36047 15.8388 1.36047C7.47742 1.36047 0.699219 8.13867 0.699219 16.5C0.699219 24.8613 7.47742 31.6395 15.8388 31.6395Z"
          stroke="#C5CCCA"
          strokeMiterlimit="10"
        />
      </svg>
    )
  }
}

export default AccordionButton
