import React from "react"

const AccordionLink = ({ text, link, icon }) => {
  return (
    <a
      href={link}
      rel="noreferrer"
      target="_blank"
      className="group flex justify-between"
    >
      <div>
        {text} {icon}
      </div>
      <span className="hidden group-hover:inline">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="inline-block"
        >
          <path
            d="M4 0V3.18387L12.0147 6.4951L4 9.81613V13L13 6.4951L4 0Z"
            fill="#C5CCCA"
          />
          <path
            d="M11.2155 6.49509L0 4.97662V8.01355L11.2155 6.49509Z"
            fill="#C5CCCA"
          />
        </svg>
      </span>
    </a>
  )
}

export default AccordionLink
