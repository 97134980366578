import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import ThemeContext from "../context/ThemeContext"
import { useInView } from "react-intersection-observer"
import MoonbirdVideo from "../images/birb02.webm"
import MoonbirdVideoMp4 from "../images/birb02.mp4"

const Moonbird2 = props => {
  const comeIntoView = useAnimation()
  const [ref, inView] = useInView()

  const variants = {
    visible: {
      y: "5%",
      transition: {
        delay: 0.75,
        type: "spring",
        stiffness: 100,
        duration: 3,
        mass: 0.5,
      },
    },
    hidden: { y: "100%" },
    flickHead: {
      scaleX: -1,
      transition: {
        duration: 0.01,
        delay: 2,
        repeatDelay: 2,
        repeat: Infinity,
        repeatType: "reverse",
      },
    },
  }

  useEffect(() => {
    if (inView) {
      comeIntoView.start("visible")
    }
  }, [comeIntoView, inView])

  return (
    <div {...props}>
      <ThemeContext.Consumer>
        {theme => (
          <div className="overflow-hidden relative -z-10 top-px" ref={ref}>
            <motion.div
              animate={comeIntoView}
              variants={variants}
              initial="hidden"
            >
              <video
                className="lg:max-w-[30rem] w-full h-auto"
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={MoonbirdVideo} type="video/webm" />
                <source src={MoonbirdVideoMp4} type="video/mp4" />
              </video>
            </motion.div>
          </div>
        )}
      </ThemeContext.Consumer>
    </div>
  )
}

export default Moonbird2
