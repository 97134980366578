import React from "react"
import AccordionButton from "./AccordionButton"
import AccordionLink from "./AccordionLink"
import ThemeContext from "../../context/ThemeContext"

const AccordionSection = ({ title, list, index, sectionSelect, selected }) => {
  const sectionBaseClasses =
    "cursor-pointer relative uppercase font-light tracking-widest"

  const sectionSmClasses = "flex justify-between py-2 px-3"
  const sectionMdClasses =
    "md:vertical-rl md:items-center md:justify-between md:flex md:rotate-180 md:text-left md:pl-3"

  const accordionBaseClasses = "border border-current"
  const accordionSmClasses = "border-b-0 last:border-b"
  const accordionMdClasses =
    "md:transition-flex md:flex md:p-3 md:border md:border-r-0 md:last:border-r"

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div
          className={`${accordionBaseClasses} ${accordionSmClasses} ${accordionMdClasses} ${
            selected ? "md:flex-grow" : ""
          }`}
        >
          <div
            onClick={() => sectionSelect(index)}
            onKeyDown={() => sectionSelect(index)}
            role="button"
            tabIndex={0}
            className={`${sectionBaseClasses} ${sectionSmClasses} ${sectionMdClasses}`}
          >
            <div className="md:pb-4 md:max-h-[160px] md:leading-[1.1]">
              {title}
            </div>
            <AccordionButton open={selected} />
          </div>
          <div
            className={`transition overflow-hidden ${
              selected ? "h-auto md:w-full" : "h-0 md:w-0"
            }`}
          >
            <ul className="w-full mb-12 md:mb-0 px-3 md:px-3">
              {list.map((item, idx) => (
                <li
                  className={`w-full py-1.5 text-lg font-light border-b ${
                    theme.playful ? "border-[#382390]" : "border-[#353535]"
                  }`}
                  key={idx}
                >
                  <AccordionLink
                    text={item.name}
                    icon={item.icon}
                    link={item.link}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default AccordionSection
